<template>
	<Metier name="expedition-maritime" title="Expéditions maritimes" :main-menu="[{title: 'Contremaître de jour Maritime', missions: ['Prépare et coordonne les travaux de maintenance relatifs aux appontements', 'Supervise le personnel de sécurité sur les opérations maritimes', 'Contrôle les dossiers des navires à la fin des escales', 'Est l\'interlocuteur de la plateforme avec la Capitainerie de Saint-Nazaire '], competences: ['Connaissance du matériel et des installations maritimes', 'Connaissance de la réglementation douanière, portuaire, administrative et maritime', 'Connaissance des règles et procédures de sécurité', 'Bonne pratique de l\'anglais'], qualites: ['Leadership', 'Organisation', 'Sens relationnel', 'Esprit d\'analyse et de synthèse']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
